<template>
  <div class="page">
    <div class="header">
      <h3 class="sub-title">
        Лекарственные препараты
      </h3>
      <div class="button-box">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red"
              class="ma-2 white--text"
              @click="addMedication()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                left
                dark
              >
                mdi-plus
              </v-icon>
              Добавить
            </v-btn>
          </template>
          <span>Добавить новый параметр для поиска</span>
        </v-tooltip>
        <v-btn
          color="white"
          class="ma-2 red--text"
          @click="startSearch()"
        >
        <v-icon
          left
          dark
        >
          mdi-magnify
        </v-icon>
          Поиск
        </v-btn>
      </div>
    </div>
    <v-skeleton-loader
     v-if="!load"
      class="mx-auto"
      type="table-tbody"
    ></v-skeleton-loader>
    <table class="table table-white" v-else>
      <thead>
        <tr>
          <th
            v-for="th in head"
            :key="th"
            class="text-center">
            {{th}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(td, index) in items"
          :key="index"
        >
          <td class="text-center">
            <v-autocomplete
              :items="getMedicines"
              placeholder="Код или наименование препарата"
              v-model="td.code"
            ></v-autocomplete>
          </td>
          <td class="text-center">
            <v-icon color="red" v-if="index >= 1" size="16px" @click="removeMedication(index)">
              mdi-trash-can-outline
            </v-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex'

  export default {
    name: 'Medications',
    data () {
      return {
        head: [
          'Лекарственный препарат',
          'Удалить'
        ],
        load: false,
        filter: {
          medicineCodes: []
        },
        items: [
          {
            code: '',
          },
        ]
      }
    },
    computed : {
      ...mapGetters({
          getMedicines: 'lookups/getMedicines',
      }),
    },
    methods: {
      ...mapActions({
        medicines: 'lookups/medicines',
        smpFilter: 'smp/smpFilter'
      }),
      ...mapMutations({
        smpSearch: 'smp/smpSearch',
      }),
      addMedication() {
        this.items.push({
          code: '',
        })
      },
      removeMedication(index) {
        this.items.splice(index, 1);
      },
      startSearch() {
        this.filter.medicineCodes = this.items.map(item => {
          return item.code
        })
        this.filter.medicineName = this.items.map(item => {
          return item.name
        })
        this.smpFilter(this.filter)
        this.smpSearch(true)
      }
    },
    mounted() {
      this.smpSearch(false)
      this.medicines().then(() => this.load = true)
    },
    destroyed() {
      this.smpFilter({})
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/scss/vars.scss";
  @import "~@/scss/mixins.scss";
  @import "./style.scss";
</style>
